const getOrderStatus = (status) => {

    switch (status) {
        case "PAY_PROCESSING":
            return "支付中"
        case "PAY_SUCCESS":
            return "支付成功"
        case "PAY_FAIL":
            return "支付失败"
        case "COMPLETE":
            return "完成"
        case "CLOSE":
            return "关闭"
        case "CANCEL":
            return "取消"
    }

    return ""

}

export default {
    getOrderStatus
}