import React, { Component } from 'react';
import './PayPwd.less';
import { ActivityIndicator, InputItem, List, Toast, Modal } from 'antd-mobile';
import request from '../../utils/Http';

type Props = {
    history: Object,
};



class Pwd extends Component<Props, State> {

    doing: boolean = false;
    account: string = '';
    num: string = '';

    state = {
        animating: false,
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-支付密码";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
        //   let user_id = window.cookie.get('user_id');
        //   if (user_id !== undefined && user_id !== null && user_id !== 'undefined' && user_id.length > 0) {
        //     this.props.history.push({ pathname: 'Home'});
        //   }
    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
    }

    render() {
        return (
            <div className="Pwd">
                <List className="forss">
          
                    <InputItem
                        className="accountInput"
                        placeholder="请输入原支付密码"
                        clear
                        type="password"
                        onChange={(v) => { this.account = v }}
                    >原支付密码</InputItem>

                    <InputItem
                        placeholder="请输入新支付密码"
                        clear
                        type="password"
                        moneyKeyboardAlign="right"
                        onChange={(v) => { this.num = v }}
                    >新支付密码</InputItem>

                </List>
                <div className="submit" onClick={this._submit}>提交</div>

                <div className="loading">
                    <ActivityIndicator
                        toast
                        text='请求中...'
                        animating={this.state.animating}
                    />
                </div>
            </div>
        );
    }



    _submit = () => {

        if(this.account.length === 0){
            this._showAlert('请输入原支付密码');
            return
        }
        if(this.num.length === 0){
            this._showAlert('请输入新支付密码');
            return
        }


        if (!this.doing) {
            this.doing = true;
            this._showModal();

            let postData = {
                oldPassword:this.account,
                password:this.num,
                shopId: window.cookie.get('shopId')
            }

         


            request('api/web/shop/update/two/password', postData)
                .then((data) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._showToast(data.msg);
                    if (data.code === 0) {
                        setTimeout(()=>{
                            this.props.history.goBack();
                        }) 
                    } 
                }).catch((error) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._failToast();
                });
        }
        
    }

    _showAlert = (text: string) => {
        Modal.alert(text, null, [
            { text: '确定', onPress: () => { } },
        ]);
    }

    _showModal = () => {
        this.setState({ animating: true });
    }

    _hiddenModal = () => {
        this.setState({ animating: false, });
    }

    _failToast = () => {
        Toast.fail('请求失败，请稍后再试', 2);
    }

    _showToast = (msg: string) => {
        Toast.info(msg, 2);
    }



}

export default Pwd;