import React, { Component } from 'react';
import './WithdrawHistory.less';
import { ListView, PullToRefresh, Toast } from 'antd-mobile';
import getRequest from '../../utils/HttpGet';

const documentElement = document.documentElement;

type Props = {
  history: Object,
};


type State = {
  dataSource: Array<Object>,
  refreshing: boolean,
  listData: any,
  hasMore: boolean,
  showList: boolean,
  showNoListDataImage: boolean,
  isLoading: boolean,
  showFinishTxt: boolean,
  height: number,
};


const listData = new ListView.DataSource({
  rowHasChanged: (row1, row2) => row1 !== row2,
});

class WithdrawHistory extends Component<Props, State> {

  page: number = 1;
  pagesize: number = 20;
  doing: boolean = false;
  dataSource: Array<Object> = [];
  manuallyRefresh: boolean = false;

  state = {
    dataSource: [],
    refreshing: false,
    listData,
    hasMore: true,
    showList: false,
    showNoListDataImage: false,
    isLoading: false,
    showFinishTxt: true,
    height: documentElement !== null ? documentElement.clientHeight : 0,

  }


  componentWillMount() {

  }

  componentDidMount() {
    document.title = "惠集中-提现记录";
    if (document.body !== null) {
      document.body.style.backgroundColor = '#FFFFFF';
    }
    this._getChartListRequest(1, this.pagesize, "refreshing");
  }




  componentWillUnmount() {
    if (document.body !== null) {
      document.body.style.backgroundColor = 'rgb(241,241,241)';
    }
  }

  onRefresh = () => {
    if (!this.manuallyRefresh) {
      this.setState({ refreshing: true });
    } else {
      this.manuallyRefresh = false;
    }
    this.dataSource = [];
    this.setState({
      hasMore: true,
    });

    this._getChartListRequest(1, this.pagesize, "refreshing");
  };

  onEndReached = () => {
    if (this.state.isLoading || !this.state.hasMore) {
      return;
    }
    this.setState({ isLoading: true });
    this._getChartListRequest(this.page + 1, this.pagesize, "loading");
  };

  scrollingComplete = () => {
    this.setState({ showFinishTxt: false });
  }


  render() {

    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F1F1F1',
          height: 1,
          margin: '0 0.3rem 0 0.3rem',
        }}
      />
    );
    const row = (rowData, sectionID, rowID) => {
      return (
        <div key={rowID} className="itemBox">
          <div className="infoBox">
            <div>提现金额：￥{rowData.price}</div>
            <div className="point">{rowData.type==='BANK_CARD'?"银行卡":"微信"}-
            {rowData.type==='BANK_CARD'?rowData.bankCard:rowData.weChatId}</div>
          </div>
          <div className="statusBox">
            <div>
              {rowData.status === 'PROCESSING' ||rowData.status === 'CREATE' ? "审核中" :rowData.status === 'SUCCESS' ? "审核成功":"审核失败" }
            </div>
            <div className="point">
              {rowData.createTime}
            </div>
          </div>
        </div>
      );
    };


    return (
      <div className="WithdrawHistory">
        {this.state.showNoListDataImage ? <div className="noDataBox">
          <img src={require('../../img/no_data.png').default} className="no_data" alt="" />
                    暂无数据
                </div> : null}
        {this.state.showList ?
          <ListView
            ref={el => this.lv = el}
            dataSource={this.state.listData}
            // renderHeader={() => (<div style={{ height: '2.61rem', textAlign: 'center', background: 'rgb(241, 241, 241)' }}>
            // </div>)}
            renderFooter={() => (<div style={{ height: '0.5rem', textAlign: 'center', background: '#f1f1f1', lineHeight: '0.5rem' }}>
              {this.state.isLoading ? '加载中...' : null}
              {!this.state.hasMore ? '没有更多啦' : null}
            </div>)}
            renderRow={row}
            renderSeparator={separator}
            className="am-list"
            style={{
              height: this.state.height,
              background: '#f1f1f1',
            }}
            initialListSize={20}
            pageSize={20}
            scrollerOptions={{ scrollbars: false, scrollingComplete: this.scrollingComplete }}
            pullToRefresh={<PullToRefresh
              refreshing={this.state.refreshing}
              onRefresh={this.onRefresh}
            />}
            scrollRenderAheadDistance={500}
            scrollEventThrottle={20}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
          /> : null}
      </div>
    );
  }


  _getChartListRequest = (page: number, pagesize: number, type: string) => {
    if (!this.doing) {
      this.doing = true;
      let shopId = window.cookie.get('shopId');
      let postData = {
        pageNum: page,
        pageSize: pagesize
      }
      getRequest('api/web/shop/withdraw/' + shopId, postData)
        .then((data) => {
          if (data.code === 0) {
            if (data.data.list.length === pagesize) {
              this.dataSource = this.dataSource.concat(data.data.list);
              this.setState({
                listData: type === 'loading' ? this.state.listData.cloneWithRows(this.dataSource) : this.state.listData.cloneWithRows(data.data.list),
                hasMore: true,
                showList: true,
                showNoListDataImage: false,
              });
              if (page * pagesize >= data.data.total) {
                this.setState({
                  hasMore: false,
                });
              } else {
                this.page = page;
              }
            } else if (data.data.list.length === 0 && page === 1) {
              this.setState({
                hasMore: false,
                showList: false,
                showNoListDataImage: true,
              });
            } else {
              this.dataSource = this.dataSource.concat(data.data.list);
              this.setState({
                listData: type === 'loading' ? this.state.listData.cloneWithRows(this.dataSource) : this.state.listData.cloneWithRows(data.data.list),
                hasMore: true,
                showList: true,
                showNoListDataImage: false,
              });
              this.setState({
                hasMore: false,
              });
            }
          } else {
            this._showToast(data.msg);
            this.setState({
              hasMore: false,
            });
          }
          this.doing = false;
          type === 'loading' ? this.setState({ isLoading: false, }) : this.setState({ refreshing: false, showFinishTxt: true, });
        }).catch((error) => {
          this._showToast('请求失败，请稍后再试');
          this.doing = false;
          type === 'loading' ? this.setState({ isLoading: false, }) : this.setState({ refreshing: false, showFinishTxt: true, });
        });
    } else {
      type === 'loading' ? this.setState({ isLoading: false, }) : this.setState({ refreshing: false, showFinishTxt: true, });
    }
  }

  _failToast = () => {
    Toast.fail('请求失败，请稍后再试', 2);
  }

  _showToast = (msg: string) => {
    Toast.info(msg, 2);
  }





}

export default WithdrawHistory;