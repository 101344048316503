import React, { Component } from 'react';
import './Login.less';
import { ActivityIndicator, Toast, Modal } from 'antd-mobile';
import request from '../../utils/Http';

type Props = {
    history: Object,
};

class Login extends Component<Props, State> {

    doing: boolean = false;
    phone: string = 'test';
    pwd: string = '654321';

    state = {
        animating: false,
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-商户登录";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
        let shopId = window.cookie.get('shopId');
        if (shopId !== undefined && shopId !== null && shopId !== 'undefined' && shopId.length > 0) {
            this.props.history.replace({ pathname: 'home' });
        }
        console.log(shopId);
    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
    }

    render() {
        return (
            <div className="Login">
                <img src={require("../../img/logo.png").default} className="LogoImg" alt="logo" />

                <div className='inputBox'>
                    <img src={require('../../img/phone.png').default} alt='' className='icon' />
                    <input maxLength={11} onBlur={this._kickBack} onChange={(data) => { this.phone = data.target.value; }} placeholder='请输入手机号' className="input" />
                </div>

                <div className='line' />

                <div className='inputBox'>
                    <img src={require('../../img/code.png').default} alt='' className='icon' />
                    <input type='password' onBlur={this._kickBack} onChange={(data) => { this.pwd = data.target.value; }} placeholder='请输入密码' className="input" />
                </div>

                <div className='line' />

                <div className="LoginBtn" onClick={this._login}>登录</div>
                {/* <div className="LoginPro">
            <span className = "Pro">登录即表示同意</span>
            <span className ="LoginProBtn" onClick={this._protocol}>《用户注册协议》</span>
          </div> */}

                <div className="loading">
                    <ActivityIndicator
                        toast
                        text='请求中...'
                        animating={this.state.animating}
                    />
                </div>
            </div>
        );
    }


    _login = () => {
        //this.props.history.push({ pathname: "home" });
        let formIsOk = this._validatePhoneForm();
        if (formIsOk) {
            this._loginRequest();
        }

    }


    _validatePhoneForm = () => {
        let formIsOk = false;
        if (this.phone.length === 0) {
            this._showAlert('请输入手机号');
        } else if (this.pwd.length === 0) {
            this._showAlert('请输入密码');
        } else {
            formIsOk = true;
        }
        return formIsOk;
    }

    _loginRequest = () => {
        if (!this.doing) {
            this.doing = true;
            this._showModal();

            let postData = {
                login: this.phone,
                pwd: this.pwd
            }

            request('api/web/shop/login', postData)
                .then((data) => {
                    this.doing = false;
                    this._hiddenModal();
                    if (data.code === 0) {
                        window.cookie.set('shopId', data.data.id);
                        this._goNextPage('home');
                    } else {
                        this._showToast(data.msg);
                    }
                }).catch((error) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._failToast();
                });
        }
    }

    _goNextPage = (pageName: string) => {
        const history = this.props.history;
        history.replace({ pathname: pageName });
    }


    _showAlert = (text: string) => {
        Modal.alert(text, null, [
            { text: '确定', onPress: () => { } },
        ]);
    }

    _showModal = () => {
        this.setState({ animating: true });
    }

    _hiddenModal = () => {
        this.setState({ animating: false, });
    }

    _failToast = () => {
        Toast.fail('请求失败，请稍后再试', 2);
    }

    _showToast = (msg: string) => {
        Toast.info(msg, 2);
    }

    // 由于iOS端微信webview，输入框失去焦点之后，页面不会自动下滑回到原位，所以自己处理。
    _kickBack = () => {
        setTimeout(() => {
            if (document.body !== null) {
                window.scrollTo(0, document.body.scrollTop + 1);
                if (document.body !== null) {
                    document.body.scrollTop >= 1 && window.scrollTo(0, document.body.scrollTop - 1);
                }
            }
        }, 10);
    }



}

export default Login;