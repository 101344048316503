import React, { Component } from 'react';
import './Withdraw.less';
import { ActivityIndicator, Picker, InputItem, List, Toast, Modal } from 'antd-mobile';
import request from '../../utils/Http';

type Props = {
    history: Object,
};

const data = [
    {
        label: '微信',
        value: 'WE_CHAT',
    },
    {
        label: '银行卡',
        value: 'BANK_CARD',
    }
]

class Withdraw extends Component<Props, State> {

    doing: boolean = false;
    //account: string = '';
    num: string = '';
    payPwd: String = ""

    state = {
        animating: false,
        typeSel: '',
        account: ""
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-提现";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
        let type = window.cookie.get('withdraw_type');
        if (type !== undefined && type !== null && type !== 'undefined' && type.length > 0) {
            this.setState({
                typeSel: [type]
            })
        }
        let account = window.cookie.get('withdraw_account');
        if (account !== undefined && account !== null && account !== 'undefined' && account.length > 0) {
            this.setState({
                account: account
            })
        }

    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
    }

    render() {
        return (
            <div className="Withdraw">
                <List className="forss">
                    <Picker data={data} cols={1}
                        className="forss"
                        onChange={v => this.setState({ typeSel: v })}
                        onOk={v => this.setState({ typeSel: v })}
                        value={this.state.typeSel}>

                        <List.Item arrow="horizontal">提现账户</List.Item>
                    </Picker>

                    <InputItem
                        className="accountInput"
                        placeholder="请输入到账账号"
                        clear
                        value={this.state.account}
                        onChange={(v) => {
                            this.setState({
                                account: v
                            })
                        }}
                    >到账账号</InputItem>

                    <InputItem
                        placeholder="请输入提现金额"
                        clear
                        type="money"
                        moneyKeyboardAlign="right"
                        onChange={(v) => { this.num = v }}
                    >提现金额</InputItem>

                    <InputItem
                        placeholder="请输入支付密码"
                        clear
                        type="password"
                        moneyKeyboardAlign="right"
                        onChange={(v) => { this.payPwd = v }}
                    >支付密码</InputItem>

                </List>
                <div className="submit" onClick={this._submit}>提交</div>

                <div className="loading">
                    <ActivityIndicator
                        toast
                        text='请求中...'
                        animating={this.state.animating}
                    />
                </div>
            </div>
        );
    }



    _submit = () => {
        if (this.state.typeSel === '') {
            this._showAlert('请选择提现账户');
            return
        }
        if (this.state.account.length === 0) {
            this._showAlert('请输入到账账号');
            return
        }
        if (this.num.length === 0) {
            this._showAlert('请输入提现金额');
            return
        }
        if (this.payPwd.length === 0) {
            this._showAlert('请输入支付密码');
            return
        }

        if (!this.doing) {
            this.doing = true;
            this._showModal();

            let postData = {
                type: this.state.typeSel[0],
                price: this.num,
                shopId: window.cookie.get('shopId'),
                password: this.payPwd
            }

            if (this.state.typeSel[0] === "WE_CHAT") {
                postData.weChatId = this.state.account
            }

            if (this.state.typeSel[0] === "BANK_CARD") {
                postData.bankCard = this.state.account
            }


            request('api/web/shop/withdraw', postData)
                .then((data) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._showToast(data.msg);
                    if (data.code === 0) {

                        window.cookie.set('withdraw_type', this.state.typeSel[0]);
                        window.cookie.set('withdraw_account', this.state.account);

                        setTimeout(() => {
                            this.props.history.goBack();
                        })
                    }
                }).catch((error) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._failToast();
                });
        }

    }

    _showAlert = (text: string) => {
        Modal.alert(text, null, [
            { text: '确定', onPress: () => { } },
        ]);
    }

    _showModal = () => {
        this.setState({ animating: true });
    }

    _hiddenModal = () => {
        this.setState({ animating: false, });
    }

    _failToast = () => {
        Toast.fail('请求失败，请稍后再试', 2);
    }

    _showToast = (msg: string) => {
        Toast.info(msg, 2);
    }



}

export default Withdraw;