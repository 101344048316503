import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
//import asyncComponent from "./utils/AsyncComponent";

import { HashRouter as Router, Route } from "react-router-dom";

import "./utils/fixed"

//const App = asyncComponent(() => import('./App'));
// const Login = asyncComponent(() => import('./containers/login/Login'));
// const Home = asyncComponent(() => import('./containers/home/Home'));
// const Withdraw = asyncComponent(() => import('./containers/withdraw/Withdraw'));
// const PayHistory = asyncComponent(() => import('./containers/payHistory/PayHistory'));
// const WithdrawHistory = asyncComponent(() => import('./containers/withdrawHistory/WithdrawHistory'));

import Login from './containers/login/Login'
import Home from "./containers/home/Home";
import Withdraw from './containers/withdraw/Withdraw'
import PayHistory from './containers/payHistory/PayHistory'
import WithdrawHistory from './containers/withdrawHistory/WithdrawHistory'
import Pwd from './containers/pwd/Pwd'
import ShopInfo from './containers/shopInfo/ShopInfo'
import ChooseLocate from './containers/chooseLocate/ChooseLocate'
import PayDetail from './containers/payDetail/PayDetail'
import PayPwd from './containers/payPwd/PayPwd'

const root = document.getElementById('root');

if (root !== null) {
  ReactDOM.render(
    <Router>
      <div className='index'>
        <Route exact path="/" component={Login} />  {/* 登录 */}
        <Route exact path="/home" component={Home} />  {/* 首页 */}
        <Route exact path="/withdraw" component={Withdraw} />  {/* 提现 */}
        <Route exact path="/payHistory" component={PayHistory} />  {/* 交易记录 */}
        <Route exact path="/withdrawHistory" component={WithdrawHistory} />  {/* 提现记录 */}
        <Route exact path="/pwd" component={Pwd} />  {/* 修改密码 */}
        <Route exact path="/shopInfo" component={ShopInfo} />  {/* 店铺信息 */}
        <Route exact path="/chooseLocate" component={ChooseLocate} />  {/* 选择地址 */}
        <Route exact path="/payDetail" component={PayDetail} />  {/* 交易详情 */}
        <Route exact path="/payPwd" component={PayPwd} />  {/* 支付密码 */}
      </div>
    </Router>,
    root
  );
}




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
