import React, { Component } from 'react';
import './PayDetail.less';
import { List } from 'antd-mobile';
import StringUtil from '../../utils/StringUtil'

type Props = {
    history: Object,
};

const Item = List.Item;
const Brief = Item.Brief;

class PayDetail extends Component<Props, State> {
  

    state = {
        orderInfo: {}
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-交易详情";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }

        let orderStr = window.cookie.get('orderInfo');
        let orderInfo = JSON.parse(orderStr)
        this.setState({
            orderInfo
        })
    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }



    }

    render() {
        return (
            <div className="PayDetail">
                <List className="forss">
                    <Item
                        extra={<div className="orderId">{this.state.orderInfo.orderCode}</div>}
                        wrap
                    >
                        交易单号
                    </Item>
                    <Item
                        extra={this.state.orderInfo.price}
                    >
                        消费金额
                    </Item>
                    <Item
                        extra={this.state.orderInfo.relPrice}
                    >
                        实付金额
                    </Item>
                    <Item
                        extra={this.state.orderInfo.point}
                    >
                        使用积分
                    </Item>
                    <Item
                        extra={StringUtil.getOrderStatus(this.state.orderInfo.status)}
                    >
                        交易状态
                    </Item>
                    <Item
                        extra={this.state.orderInfo.createTime}
                        wrap
                    >   
                        交易时间
                    </Item>

                </List>
                <div className="submit" onClick={this._submit}>确定</div>
            </div>
        );
    }



    _submit = () => {
        this.props.history.goBack();
    }





}

export default PayDetail;