import React, { Component } from 'react';
import './ShopInfo.less';
import { ActivityIndicator, DatePicker, WingBlank, ImagePicker, InputItem, List, Toast, Modal ,ActionSheet} from 'antd-mobile';
import request from '../../utils/Http';
import QRCode from 'qrcode.react'
import getRequest from '../../utils/HttpGet';

type Props = {
    history: Object,
};


const Item = List.Item;

class ShopInfo extends Component<Props, State> {

    doing: boolean = false;
    account: string = '';
    num: string = '';
    jd: double = 0;
    wd: double = 0;

    state = {
        animating: false,


        logo: undefined,
        shopName: "",
        area: "",
        startTime: "",
        endTime: "",
        shopImage: [],
        latitude: 0,
        longitude: 0,
        qrcode: 'downloadQrcode',
        chooseShow: 'ChooseLocateHide',
        jd:0,
        wd:0,
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-店铺信息";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
        //   let user_id = window.cookie.get('user_id');
        //   if (user_id !== undefined && user_id !== null && user_id !== 'undefined' && user_id.length > 0) {
        //     this.props.history.push({ pathname: 'Home'});
        //   }

        let shopId = window.cookie.get('shopId')
        this._getShopInfo()
        this.setState({
            qrcode: 'https://www.huituan1.cn/shopinfo?shopId=' + shopId
        })


        window.AMapUI.loadUI(['misc/PositionPicker'], (PositionPicker) => {
            this.map = new window.AMap.Map('container',{ zoom:15});
            window.AMap.plugin(['AMap.ToolBar', 'AMap.Geolocation'], () => {//异步加载插件
                var toolbar = new window.AMap.ToolBar();
                this.map.addControl(toolbar);
                var geolocation = new window.AMap.Geolocation();
                this.map.addControl(geolocation);
            });
            var positionPicker = new PositionPicker({
                mode: 'dragMap',//设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: this.map//依赖地图对象
            });
            //TODO:事件绑定、结果处理等
            positionPicker.on('success', (positionResult) => {
                console.log(positionResult);
                this.setState({
                    jd: positionResult.position.lng,
                    wd: positionResult.position.lat
                })
            });
            positionPicker.on('fail', (positionResult) => {
                console.log(positionResult);
                this.setState({
                    jd: 0,
                    wd: 0
                })
            });

            positionPicker.start();

        });
    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
    }



    render() {
        return (
            <div className="ShopInfo">
                <List className="forss">
                    <Item
                        arrow="horizontal"
                        extra={<img src={this.state.logo} alt='' className="logo" />}
                        multipleLine
                        onClick={()=>this._selectImg()}
                    >
                        Logo
                    </Item>
                    <InputItem
                        className="accountInput"
                        placeholder="请输入店铺名称"
                        clear
                        value={this.state.shopName}
                        onChange={(v) => {
                            this.setState({ shopName: v })
                        }}
                    >店铺名称</InputItem>
                    <InputItem
                        className="accountInput"
                        placeholder="请输入店铺地址"
                        clear
                        value={this.state.area}
                        onChange={(v) => {
                            this.setState({ area: v })
                        }}
                    >店铺地址</InputItem>
                    <Item arrow="horizontal"
                        extra={this.state.longitude + "," + this.state.latitude}
                        onClick={() => { this._goMap() }}>店铺坐标</Item>
                    <DatePicker
                        mode="time"
                        value={this.state.startTime}
                        onChange={startTime => {
                            this.setState({ startTime })
                        }
                        }
                    >
                        <List.Item arrow="horizontal">开始营业时间</List.Item>
                    </DatePicker>

                    <DatePicker
                        mode="time"
                        value={this.state.endTime}
                        onChange={endTime => {
                            this.setState({ endTime })
                        }
                        }
                    >
                        <List.Item arrow="horizontal">结束营业时间</List.Item>
                    </DatePicker>
                    <Item>店铺介绍</Item>
                    <WingBlank>
                        <ImagePicker
                            files={this.state.shopImage}
                            onChange={this.onImgChange}
                            //onImageClick={(index, fs) => console.log(index, fs)}
                            onAddImageClick={(e)=>{
                                e.preventDefault();
                                this._selectImg1()
                            }}
                            multiple={true}
                        />
                    </WingBlank>


                </List>
                <a download id='aId' title="点我下载！">
                    <QRCode id='qrid' value={this.state.qrcode} onClick={this.downloadQrcode} />
                </a>
                <div>店铺二维码（点击图片下载）</div>

                <div className="posView"> </div>

                <div className="submit" onClick={this._submit}>保存<span className="btnInfo">(只有保存了才会生效)</span></div>

                <div className={this.state.chooseShow}>
                    {/* <iframe
                    id="test"
                    src="https://m.amap.com/picker/?key=6bdee20db75c9489a0a003e432d97ef2"
                ></iframe> */}
                    <div id="container"></div>
                    <div className="addressInfo">
                        <div className="infoBox">
                            {/* <div>{this.state.address}</div> */}
                            <div className="info">经度：{this.state.jd}<br />纬度：{this.state.wd}</div>
                            <div className="btn" onClick={()=>this._onMapConfirm()}>确定</div>
                        </div>

                    </div>
                </div>
                <input onChange={(data) => { this._onChange(data, this.refs.camcorder) }} ref="camcorder" className="camcorder" type="file" accept="image/*" capture="camera" style={{ display: "none" }} />
                <input onChange={(data) => { this._onChange(data, this.refs.album) }} ref="album" className="camcorder" type="file" accept="image/*" style={{ display: "none" }} />
                <input onChange={(data) => { this._onChange1(data, this.refs.shopImg) }} ref="shopImg" className="camcorder" type="file" accept="image/*" style={{ display: "none" }} />
               
                <div className="loading">
                    <ActivityIndicator
                        toast
                        text='请求中...'
                        animating={this.state.animating}
                    />
                </div>
            </div>
        );
    }


    onImgChange = (files, type, index) => {
        console.log(files, type, index);
        this.setState({
            shopImage:files
        });
    }




    _getShopInfo = () => {
        this._showModal();
        let shopId = window.cookie.get('shopId')
        getRequest('api/shop/' + shopId)
            .then((data) => {
                this._hiddenModal();
                if (data.code === 0) {

                    let resultData = data.data

                    let startDate = new Date()
                    let startTimeStrArray = resultData.startTime.split(':')
                    startDate.setHours(startTimeStrArray[0])
                    startDate.setMinutes(startTimeStrArray[1])
                    let endtDate = new Date()
                    let endTimeStrArray = resultData.endTime.split(':')
                    endtDate.setHours(endTimeStrArray[0])
                    endtDate.setMinutes(endTimeStrArray[1])


                    // let images = resultData.shopImage
                    // let newImgArray = []
                    // images.forEach((element, index) => {
                    //     let imgItem = {}
                    //     imgItem.url = element
                    //     newImgArray.push(imgItem)
                    // });


                    this.setState({
                        logo: resultData.logo,
                        shopName: resultData.shopName,
                        area: resultData.area,
                        startTime: startDate,
                        endTime: endtDate,
                        shopImage: resultData.shopImage,
                        latitude: resultData.latitude,
                        longitude: resultData.longitude

                    })
                } else {
                    this._showToast(data.msg);
                }
            }).catch((error) => {
                this._hiddenModal();
                this._failToast();
            });
    }

    _selectImg = ()=> {
        // const BUTTONS = ['立即拍照', '从相册选取', '取消'];
        // ActionSheet.showActionSheetWithOptions({
        //   options: BUTTONS,
        //   cancelButtonIndex: BUTTONS.length - 1,
        //   // title: '标题',
        //   message: '上传Logo',
        //   maskClosable: true,
        // },
        // (buttonIndex) => {
        //   if (buttonIndex === 0) {
        //     this.refs.camcorder.click();
        //   } else if (buttonIndex === 1) {
             this.refs.album.click();
        //   }
        // });
    
      }
    
      _onChange = (data,obj)=> {
        let that = this;
        var file = obj.files[0];
        that._showModal('上传图片中...');
        if (typeof file !== "undefined") {
          if(!/image\/\w+/.test(file.type)){
              alert("请确保文件为图像类型");
              that._hiddenModal();
              return false;
          }
          this._uploadLogo(file)
        } else {
          that._hiddenModal();
        }
      }
    
      
      _uploadLogo=(file)=>{
        let postData = new FormData()
        postData.append("file",file,file.name)
        let config = {
            headers:{"Content-Type":"multipart/form-data"}
        }
        request('api/file/upload', postData,config)
        .then((data) => {
            this._hiddenModal();
            this._showToast(data.msg);
            if(data.code==0){
                this.setState({
                    logo:data.data
                })
            }
       
        }).catch((error) => {
            this._hiddenModal();
            this._failToast();
        });
      }



      _selectImg1 = ()=> {
        // const BUTTONS = ['立即拍照', '从相册选取', '取消'];
        // ActionSheet.showActionSheetWithOptions({
        //   options: BUTTONS,
        //   cancelButtonIndex: BUTTONS.length - 1,
        //   // title: '标题',
        //   message: '上传Logo',
        //   maskClosable: true,
        // },
        // (buttonIndex) => {
        //   if (buttonIndex === 0) {
        //     this.refs.camcorder.click();
        //   } else if (buttonIndex === 1) {

        console.log(this.refs);

             this.refs.shopImg.click();
        //   }
        // });
    
      }
    
      _onChange1 = (data,obj)=> {
        let that = this;
        var file = obj.files[0];
        that._showModal('上传图片中...');
        if (typeof file !== "undefined") {
          if(!/image\/\w+/.test(file.type)){
              alert("请确保文件为图像类型");
              that._hiddenModal();
              return false;
              
          }
          this._uploadLogo1(file)
        } else {
          that._hiddenModal();
        }
      }
    
      
      _uploadLogo1=(file)=>{
        let postData = new FormData()
        postData.append("file",file,file.name)
        let config = {
            headers:{"Content-Type":"multipart/form-data"}
        }
        request('api/file/upload', postData,config)
        .then((data) => {
            this._hiddenModal();
            this._showToast(data.msg);
            if(data.code==0){
                let item = {}
                item.url = data.data
                let imgsArray = this.state.shopImage
                imgsArray.push(item)
                this.setState({
                    shopImage:imgsArray
                })
            }
       
        }).catch((error) => {
            this._hiddenModal();
            this._failToast();
        });
      }

    downloadQrcode = () => {
        var Qr = document.getElementById('qrid');
        let image = new Image();
        image.src = Qr.toDataURL("image/png");
        var a_link = document.getElementById('aId');
        a_link.href = image.src;
        //a_link.download = id;//好像这一行可以不写，，你可以尝试一下
    }


    _goMap = () => {
        //this.props.history.push({ pathname: "chooseLocate" });
        let pos = new window.AMap.LngLat(this.state.longitude,this.state.latitude)
        this.map.setCenter(pos)
        this.setState({
            jd:this.state.longitude,
            wd:this.state.latitude,
            chooseShow:"ChooseLocate"
        })
    }

    _onMapConfirm = ()=>{
        this.setState({
            longitude:this.state.jd,
            latitude:this.state.wd,
            chooseShow:"ChooseLocateHide"
        })
    }

    _submit = () => {

        if (!this.doing) {
            this.doing = true;
            this._showModal();

            let {jd,wd,animating,qrcode,chooseShow,startTime,endTime,shopImage,...rest} = this.state

            rest.startTime = this.dateFormat('HH:MM',startTime)
            rest.endTime = this.dateFormat('HH:MM',endTime)
            rest.images = shopImage

            let shopId= window.cookie.get('shopId')
          
            request('api/shop/info/' +shopId, rest)
                .then((data) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._showToast(data.msg);
                    if (data.code === 0) {
                        setTimeout(() => {
                            this.props.history.goBack();
                        })
                    }
                }).catch((error) => {
                    this.doing = false;
                    this._hiddenModal();
                    this._failToast();
                });
        }

    }

    _showAlert = (text: string) => {
        Modal.alert(text, null, [
            { text: '确定', onPress: () => { } },
        ]);
    }

    _showModal = () => {
        this.setState({ animating: true });
    }

    _hiddenModal = () => {
        this.setState({ animating: false, });
    }

    _failToast = () => {
        Toast.fail('请求失败，请稍后再试', 2);
    }

    _showToast = (msg: string) => {
        Toast.info(msg, 2);
    }

     dateFormat=(fmt, date) =>{
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }



}

export default ShopInfo;