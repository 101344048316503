import React, { Component } from 'react';
import './ChooseLocate.less';


type Props = {
    history: Object,
};



class ChooseLocate extends Component<Props, State> {

    address: string = '';
    jd: double = 0;
    wd: double = 0;

    state = {
        address: '',
        jd: 0,
        wd: 0
    }

    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-选择地址";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
        // var iframe = document.getElementById("test").contentWindow;
        // document.getElementById("test").onload = function() {
        //   iframe.postMessage("hello", "https://m.amap.com/picker/");
        // };
        // window.addEventListener(
        //     "message",
        //     function(e) {
        //         alert('您选择了:' + e.data.name + ',' + e.data.location)
        //     },
        //     false
        //   );



        //加载PositionPicker，loadUI的路径参数为模块名中 'ui/' 之后的部分
        window.AMapUI.loadUI(['misc/PositionPicker'], (PositionPicker) => {
            this.map = new window.AMap.Map('container');
            window.AMap.plugin(['AMap.ToolBar', 'AMap.Geolocation'], () => {//异步加载插件
                var toolbar = new window.AMap.ToolBar();
                this.map.addControl(toolbar);
                var geolocation = new window.AMap.Geolocation();
                this.map.addControl(geolocation);
            });
            var positionPicker = new PositionPicker({
                mode: 'dragMap',//设定为拖拽地图模式，可选'dragMap'、'dragMarker'，默认为'dragMap'
                map: this.map//依赖地图对象
            });
            //TODO:事件绑定、结果处理等
            positionPicker.on('success', (positionResult) => {
                console.log(positionResult);
                this.setState({
                    jd: positionResult.position.lng,
                    wd: positionResult.position.lat
                })
            });
            positionPicker.on('fail', (positionResult) => {
                console.log(positionResult);
                this.setState({
                    jd: 0,
                    wd: 0
                })
            });

            positionPicker.start();

        });


    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
    }

    render() {
        return (
            <div className="ChooseLocate">
                {/* <iframe
                    id="test"
                    src="https://m.amap.com/picker/?key=6bdee20db75c9489a0a003e432d97ef2"
                ></iframe> */}
                <div id="container"></div>
                <div className="addressInfo">
                    <div className="infoBox">
                        {/* <div>{this.state.address}</div> */}
                        <div className="info">经度：{this.state.jd}<br />纬度：{this.state.wd}</div>
                        <div className="btn">确定</div>
                    </div>

                </div>
            </div>
        );
    }






}

export default ChooseLocate;