import axios from 'axios';

//let baseUrl = 'https://www.huituan1.cn/';
let baseUrl = 'https://huituan.sszsj.com/';
//let baseUrl = 'http://3229b3026p.zicp.vip:43316/';

axios.defaults.timeout = 20000;

export default function request(url, data,config) {
  return axios.post(baseUrl + url, data,config )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}