import React, { Component } from 'react';
import './Home.less';
import { ActivityIndicator, Toast, Modal } from 'antd-mobile';
import { Line } from '@ant-design/charts';
import getRequest from '../../utils/HttpGet';

type Props = {
    history: Object,
};


const data = [
   
];

class Home extends Component<Props, State> {

    doing: boolean = false;
    phone: string = '';
    pwd: string = '';




    state = {
        animating: false,
        total: "0.00",
        num: "0",
        todayBalance:"0.00",
        lastMonth:"0",
        thisMonth:"0",
        config: {
            data,
            height: 200,
            xField: 'time',
            yField: 'orderQty',
            point: {
                size: 5,
                shape: 'diamond',
            },
        }
    }


    componentWillMount() {

    }

    componentDidMount() {
        document.title = "惠集中-首页";
        if (document.body !== null) {
            document.body.style.backgroundColor = '#FFFFFF';
        }
   
        this._getYesterday()
        this._getPoint()
        this._getLine()

        this.timer = setInterval(()=>{
            this._getYesterday()
        },5000)

    }

    componentWillUnmount() {
        if (document.body !== null) {
            document.body.style.backgroundColor = 'rgb(241,241,241)';
        }
        clearInterval(this.timer)
    }

    render() {
        return (
            <div className="Home">
                <div className="HomeTop">
                    <div className="HomeTopItem">
                        <div className="HomeTitle">可提现金额</div>
                        <div className="HomeTotal">
                            <div className="Total">{this.state.total}</div>
                            <div>元</div>
                        </div>
                    </div>
                    {/* <div className="HomeTopItem">
                        <div className="HomeTitle">今日订单</div>
                        <div className="HomeTotal">
                            <div className="Total">{this.state.num}</div>
                            <div></div>
                        </div>
                    </div> */}
                    <div className="HomeTopItem">
                        <div className="HomeTitle">今日收入</div>
                        <div className="HomeTotal">
                            <div className="Total">{this.state.todayBalance}</div>
                            <div>元</div>
                        </div>
                    </div>
                </div>
                <div  className="PointBox">
                    <div className="PointItem">
                        <div>上月积分</div>
                        <div className="Point">{this.state.lastMonth}</div>
                    </div>
                    <div className="PointItem">
                        <div>本月积分</div>
                        <div className="Point">{this.state.thisMonth}</div>
                    </div>
                    <div className="PointItem">
                        <div>今日订单</div>
                        <div className="Point">{this.state.num}</div>
                    </div>
                </div>
                <Line {...this.state.config} className="chart" />

                <div className="btnContain">
                <div className="withdrawBtn" onClick={this._withdraw}>提现申请</div>
                    <div>　</div>
                    <div className="historyBtn" onClick={this._goWithdrawHistory}>提现记录</div>
                </div>

               
                <div className="btnContain">
                    <div className="historyBtn" onClick={this._goPayHistory}>交易记录</div>
                    <div>　</div>
                    <div className="historyBtn" onClick={this._goShopInfo}>店铺信息</div>
                </div>
                <div className="btnContain">
                <div className="historyBtn" onClick={this._goPwd}>登录密码</div>
                    <div>　</div>
                    <div className="historyBtn" onClick={this._goPayPwd}>支付密码</div>
                </div>
                <div className="logoutBtn" onClick={this._logout}>
                    <img src={require('../../img/logout.png').default} alt='' className='logout' />
                    退出
                </div>

                <div className="loading">
                    <ActivityIndicator
                        toast
                        text='请求中...'
                        animating={this.state.animating}
                    />
                </div>
            </div>
        );
    }

    _withdraw = () => {
        this.props.history.push({ pathname: "withdraw" });
    }

    _getYesterday = () => {
 
        let shopId = window.cookie.get('shopId')
        getRequest('api/web/shop/board/yesterday/' + shopId)
            .then((data) => {
                if (data.code === 0) {
                    this.setState({
                        total: data.data.balance,
                        num: data.data.orderQty,
                        todayBalance:data.data.todayBalance
                    })
                } else {
                    this._showToast(data.msg);
                }
            }).catch((error) => {
       
                this._failToast();
            });
    }

    _getPoint = ()=>{
        let shopId = window.cookie.get('shopId')
        getRequest('api/web/shop/board/point/' + shopId)
            .then((data) => {
                if (data.code === 0) {
                
                    this.setState({
                        lastMonth:data.data.last_month==null?"0":data.data.last_month,
                        thisMonth:data.data.this_month==null?"0":data.data.this_month,
                    })
                } else {
                    this._showToast(data.msg);
                }
            }).catch((error) => {
                this._failToast();
            });
    }


    _getLine = () => {
        this._showModal()
        let shopId = window.cookie.get('shopId')
        getRequest('api/web/shop/board/line/' + shopId)
            .then((data) => {
                this._hiddenModal();
                if (data.code === 0) {
                    let config = this.state.config
                    config.data = data.data
                    this.setState({
                        config:config
                    })
                } else {
                    this._showToast(data.msg);
                }
            }).catch((error) => {
                this._hiddenModal();
                this._failToast();
            });
    }

    _logout = () =>{
        window.cookie.clear()
        this.props.history.replace({ pathname: "/" });
    }


    _goPayHistory = () =>{
        this.props.history.push({ pathname: "payHistory" });
    }

    _goWithdrawHistory = () =>{
        this.props.history.push({ pathname: "withdrawHistory" });
    }
 
    _goPwd = () =>{
        this.props.history.push({ pathname: "pwd" });
    }

    _goPayPwd = () =>{
        this.props.history.push({ pathname: "payPwd" });
    }

    _goShopInfo = () =>{
        this.props.history.push({ pathname: "shopInfo" });
    }

    _showAlert = (text: string) => {
        Modal.alert(text, null, [
            { text: '确定', onPress: () => { } },
        ]);
    }

    _showModal = () => {
        this.setState({ animating: true });
    }

    _hiddenModal = () => {
        this.setState({ animating: false, });
    }

    _failToast = () => {
        Toast.fail('请求失败，请稍后再试', 2);
    }

    _showToast = (msg: string) => {
        Toast.info(msg, 2);
    }


}

export default Home;